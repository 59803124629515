module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-try-ghost/gatsby-browser.js'),
      options: {"plugins":[],"ghostConfig":{"development":{"apiUrl":"https://ks-labs.aaronsteinke.com","contentApiKey":"c491fd0ed5948fb1202fafe118"},"production":{"apiUrl":"https://ks-labs.aaronsteinke.com","contentApiKey":"c491fd0ed5948fb1202fafe118"}},"siteConfig":{"siteUrl":"https://ks-labs.de","infiniteScroll":true,"postsPerPage":8,"siteTitleMeta":"KS-Labs.de - Stressbelastung gestalten, Eigenkreativität erhalten","siteDescriptionMeta":"KS-Labs hat sich zum Ziel gesetzt, jedem die Werkzeuge und Vorgehensweisen an die Hand zu geben, seine Stressbelastung zu gestalten.","shareImageWidth":1000,"shareImageHeight":523,"shortTitle":"KS Labs","siteIcon":"favicon.png","backgroundColor":"#e9e9e9","themeColor":"#15171A","gatsbyImages":true,"verbose":false,"severity":"info","mediaUrl":""},"routes":{"basePath":"/","collections":[]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-plausible/gatsby-browser.js'),
      options: {"plugins":[],"domain":"ks-labs.de","customDomain":"stats.ks-labs.de"},
    }]
